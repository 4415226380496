<template>
  <div class="user">
    <div class="globle_border" v-loading="loading">
      <div class="search">
        <el-select v-model="tendersType" placeholder="招标类型" @change="onSearch" clearable>
          <el-option v-for="item in departmentList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <el-select v-model="eligibleType" placeholder="应标资格" @change="onSearch" clearable>
          <el-option v-for="item in eligibleTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
        <!-- <el-select style="margin-left:20px" clearable v-model="bidPeriod" @change="onSearch" filterable remote
          :remote-method="getbidPeriodPagelist" :loading="bidPeriodloading" placeholder="请选择/搜索标期">
          <el-option v-for="(item,index) in bidPeriodlist" :key="index" :label="item.bidPeriod" :value="item.bidPeriod">
          </el-option>
        </el-select> -->
        <el-autocomplete style="margin-left: 20px" v-model="bidPeriod" :fetch-suggestions="getbidPeriodPagelist" placeholder="请搜索标期" @select="onSearch" value-key="bidPeriod" @change="onSearch"></el-autocomplete>
        <el-input v-model="tendersLabel" placeholder="请输入标号" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-input v-model="tendersName" placeholder="请输入标书名称" suffix-icon="el-icon-search" @change="onSearch" clearable></el-input>
        <el-button type="primary" @click="handleAdd">新增</el-button>
      </div>
      <div class="table_list globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" :show-overflow-tooltip="true" width="50">
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column prop="bidPeriod" label="标期" show-overflow-tooltip></el-table-column>
          <el-table-column prop="tendersLabel" label="标号" show-overflow-tooltip></el-table-column>
          <el-table-column prop="tendersTime" label="开标时间" show-overflow-tooltip min-width="120"></el-table-column>
          <el-table-column prop="district" label="招标地区" show-overflow-tooltip min-width="120"></el-table-column>
          <el-table-column prop="tendersName" label="标书名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="tendersType" label="招标类型" align="center" width="80">
            <template slot-scope="scope">
              <span>{{ scope.row.tendersType == 1 ? "商超" : "" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="eligibleType" label="应标资格" width="80">
            <template slot-scope="scope">
              <span v-if="scope.row.eligibleType == 1">平台资格</span>
              <span v-if="scope.row.eligibleType == 2">独标资格</span>
            </template>
          </el-table-column>
          <el-table-column prop="effectiveEndTime" label="中标有效期" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <span :title="`${scope.row.effectiveStartTime}至${scope.row.effectiveEndTime}`">{{ scope.row.effectiveStartTime }}至{{ scope.row.effectiveEndTime }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="tendersNumber" label="最大投标商品数" min-width="120" align="center"></el-table-column>
          <el-table-column prop="createdTime" label="招标发布时间" min-width="120"></el-table-column>
          <el-table-column label="状态" width="80">
            <template slot-scope="scope">
              <span :class="scope.row.status == 2 ? 'gray' : 'red'">{{ status[scope.row.status] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" fixed="right">
            <template slot-scope="scope">
              <el-button class="btn" type="primary" plain @click="handleView(scope.row)" size="small">查看</el-button>
              <!-- <el-button v-if="scope.row.startStatus == 1" class="btn" type="" plain
                @click="handleEdit(scope.$index, scope.row)" size="small">修改</el-button>
              <el-button v-if="scope.row.startStatus == 1" class="btn" type="danger" plain
                @click="handleDelete(scope.$index, scope.row)" size="small">删除</el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount"></el-pagination>
  </div>
</template>

<script>
export default {
  //   VueEditor
  // },
  data() {
    return {
      bidPeriodloading: false,
      bidPeriodlist: [],
      content: "",
      loading: false,
      submitLoding: false,
      tendersName: "", //标书名称
      tendersLabel: "", //标号
      bidPeriod: "", //标期
      tableData: [],
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
      departmentList: [
        {
          value: "1",
          label: "商超",
        },
      ],
      tendersType: "",
      eligibleType: "",
      eligibleTypes: [
        {
          value: "1",
          label: "平台资格",
        },
        {
          value: "2",
          label: "独标资格",
        },
      ],
      roleList: [],
      startStatus: "",
      options: [
        {
          value: "0",
          label: "待评标",
        },
        {
          value: "2",
          label: "已评标",
        },
      ],
      status: ["待评标", "", "已评标"],
    }
  },

  created() {
    localStorage.removeItem("productalllist")
    this.getportalPageList()
    // this.getbidPeriodPagelist();
  },
  methods: {
    //标期下拉列表
    getbidPeriodPagelist(queryString, cb) {
      this.bidPeriodloading = true
      this.$axios
        .get(this.$api.bidPeriodPage, {
          params: {
            page: 1,
            pageSize: 50,
            bidPeriod: queryString,
          },
        })
        .then(res => {
          this.bidPeriodloading = false
          this.bidPeriodlist = res.data.result.list
          cb(this.bidPeriodlist)
        })
    },
    // 招标列表
    getportalPageList() {
      this.loading = true
      this.$axios
        .get(this.$api.portalPageList, {
          params: {
            page: this.currentPage,
            pageSize: this.pageSize,
            eligibleType: this.eligibleType || null,
            tendersLabel: this.tendersLabel || null,
            tendersName: this.tendersName || null,
            bidPeriod: this.bidPeriod || null,
            tendersType: this.tendersType || null,
          },
        })
        .then(res => {
          // console.log(res);
          this.tableData = res.data.result.list
          this.totalItemsCount = res.data.result.totalCount
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },

    // 搜索
    onSearch() {
      this.currentPage = 1
      this.tableData = []
      this.getportalPageList()
      this.getbidPeriodPagelist()
    },
    // 新增（对话框）
    handleAdd() {
      this.$router.push({ path: "/biddingManagement/detail/add" })
    },
    //查看（跳转）
    handleView(row) {
      localStorage.setItem("tendersLabel", row.tendersLabel) //本地缓存标号
      this.$router.push({ path: "/biddingManagement/bidList" })
    },
    // // 修改（对话框）
    // handleEdit(index, row) {
    //   this.isAdd = false;
    //   this.dialogVisible = true;
    //   this.ruleForm = Object.assign({}, row)
    //   if (!this.ruleForm.dateRange1) this.ruleForm.dateRange1 = []
    //   if (!this.ruleForm.dateRange2) this.ruleForm.dateRange2 = []
    //   // console.log(this.$dayjs(row.effectiveStartTime).toDate());
    //   // console.log(this.$dayjs(row.effectiveEndTime).toDate());
    //   this.ruleForm.dateRange1[0] = this.$dayjs(row.applicationStartTime).toDate() //报名开始时间
    //   this.ruleForm.dateRange1[1] = this.$dayjs(row.applicationEndTime).toDate() //报名结束时间
    //   this.ruleForm.dateRange2[0] = this.$dayjs(row.effectiveStartTime).toDate()//中标有效开始时间
    //   this.ruleForm.dateRange2[1] = this.$dayjs(row.effectiveEndTime).toDate()
    //   //中标有效结束时间
    //   this.$nextTick(() => {
    //     let quill = this.$refs.vueEditor.editor;
    //     quill.clipboard.dangerouslyPasteHTML(row.tendersNotice);
    //   })

    // },

    // 删除
    // handleDelete(index, row) {
    //   this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning',
    //     center: true
    //   }).then(() => {
    //     this.$axios.put(`${this.$api.tendersInfoDelete}/${row.tendersId}`)
    //       .then((res) => {
    //         if (res.data.code == 100) {
    //           this.$message.success(res.data.desc)
    //         }
    //         this.getportalPageList()
    //       }).catch(() => { })
    //   }).catch(() => { });
    // },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e
      this.tableData = []
      this.getportalPageList()
    },
    // 换页
    handleCurrentChange() {
      this.page += 1
      this.tableData = []
      this.getportalPageList()
    },
  },
}
</script>

<style scoped lang="scss">
.user {
  ::v-deep .ql-editor {
    min-height: 200px;
  }

  .el-date-editor ::v-deep .el-range-input {
    background: transparent;
  }

  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-wrap: wrap;
    .el-select,
    .el-autocomplete,
    .el-input {
      width: 200px;
      margin-left: 20px;
      margin-bottom: 20px;
    }

    .el-button {
      margin-left: 20px;
      margin-bottom: 20px;
    }
  }

  // .el-input-number {
  //   width: 255px;
  // }

  .table_list {
    width: 100%;
    box-sizing: border-box;
    overflow-x: auto;

    .desc {
      display: -webkit-box;
      word-break: break-all;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .red {
      color: #eb2500;
    }

    .green {
      color: #069bbc;
    }

    .gray {
      color: #68706e;
    }

    .logo {
      display: block;
      width: 58px;
      height: 58px;
      border-radius: 4px;
    }

    .busilicense {
      display: block;
      width: 80px;
      height: 58px;
      border-radius: 4px;
    }

    ::v-deep .el-table__fixed-header-wrapper thead tr th {
      color: rgba(3, 16, 14, 0.9);
      background-color: #f4f6f9;
    }
  }
}
</style>
